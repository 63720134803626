import { extendObservable, toJS } from 'mobx';
import config from 'config';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import moment from 'moment';
import httpClient from 'utils/http';

const initialHouse = {
  id: '',
  ownerUserId: '',
  name: '',
  shortDetail: '',
  longDetail: '',
  roomDetail: '',
  termAndCondition: '',
  nearlyRestaurant: '',
  nearlyLandmark: '',
  checkIn: moment('14:00', 'HH:mm'),
  checkOut: moment('12:00', 'HH:mm'),
  minPerson: '',
  maxPerson: '',
  normalPerson: '',
  rankScore: 5,
  insurancePrice: '',
  category: '',
  status: 'CREATED',
  ownerUser: {},
  houseImages: [],
  houseHighlightIcons: [],
  houseFacilityIcons: [],
  houseDetailIcons: [],
  locations: [],
  bedrooms: '',
  beds: '',
  bathrooms: '',
  
};

export class HouseStore {
  constructor() {
    extendObservable(this, {
      loading: false,
      houses: [],
      house: initialHouse,
      mode: 'create',
    });
  }

  resetHouse() {
    this.house = initialHouse;
    this.mode = 'create';
  }

  getHouseJS() {
    return toJS(this.house);
  }

  getHousesJS() {
    return toJS(this.houses);
  }

  setHouse(house) {
    this.house = house;
    this.mode = 'edit'
  }

  setHouses(houses) {
    this.houses = houses;
  }

  setHouseProp(key, value) {
    this.house[key] = value;
  }

  async getHouseByOwner(id) {
    this.loading = true;
    try {
      const response = await httpClient.get(`/houses/${id}/owner`);
      this.setHouses(response || []);
      return response;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async fetchHouse(id) {
    this.loading = true;
    try {
      const response = await httpClient.get(`/houses/${id}`);

      if (response) {
        let house = response;
        const houseIcons = house.house_icons;
        const househighlightIcons = houseIcons.filter(
          f => f.type === 'highlight',
        );
        const houseFacilityIcons = houseIcons.filter(
          f => f.type === 'facility',
        );
        const houseDetailIcons = houseIcons.filter(f => f.type === 'detail');

        house.house_images = house.house_images.map((item, index) => {
          item.index_id = index;
          item.status = 'UPDATE';
          return camelcaseKeys(item);
        });

        house.house_highlight_icons = househighlightIcons.map((item, index) => {
          item.icon = camelcaseKeys(item.icon);
          item.index_id = index;
          item.status = 'UPDATE';
          return camelcaseKeys(item);
        });
        house.house_facility_icons = houseFacilityIcons.map((item, index) => {
          item.icon = camelcaseKeys(item.icon);
          item.index_id = index;
          item.status = 'UPDATE';
          return camelcaseKeys(item);
        });
        house.house_detail_icons = houseDetailIcons.map((item, index) => {
          item.icon = camelcaseKeys(item.icon);
          item.index_id = index;
          item.status = 'UPDATE';
          return camelcaseKeys(item);
        });
        house.locations = house.locations.map((item, index) => {
          item.status = 'UPDATE';
          return camelcaseKeys(item);
        });

        this.setHouse(camelcaseKeys(house) || initialHouse);
      }
    } catch (error) {
      throw error;
      // console.log('## Error when call fetchHouse(): ', error);
      // throw new Error('ไม่สามารถโหลดข้อมูลได้');
    } finally {
      this.loading = false;
    }
  }

  async fetchHouses() {
    this.loading = true;
    try {
      const response = await httpClient.get(`/houses?isActiveOnly=true`);
      this.setHouses(response || []);
    } catch (error) {
      throw error;
      // console.log(error.message);
    } finally {
      this.loading = false;
    }
  }

  async saveHouse() {
    try {
      this.loading = true;
      const data = snakecaseKeys(this.house);

      data.check_in = moment(this.house.checkIn, 'HH:mm').format('HH:mm');
      data.check_out = moment(this.house.checkOut, 'HH:mm').format('HH:mm');

      let response;
      let url = `${config.api.url}/houses`;

      if (data.id !== null && data.id !== undefined && data.id !== '') {
        console.log('## Update House');
        response = await httpClient.put(url + `/${data.id}`, data);
      } else {
        console.log('## Insert House');
        data.id = 0;
        response = await httpClient.post(url, data);
      }

      return response;
    } catch (error) {
      // throw error;
      console.log('## Error when call SaveHouse(): ', error);
      throw new Error(error.response.data.code);
    } finally {
      this.loading = false;
    }
  }

  async deleteHouse(id) {
    this.loading = true;
    try {
      let url = `/houses/${id}/status`;
      let response = await httpClient.delete(url);
      if (response) {
        this.houses = this.houses.filter(val => val.id !== response.id);
      }
      return response;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }
}

const houseStore = new HouseStore();
export default houseStore;
